import { AUDIOS } from '~/const/assets'
import { getGlobalState } from '~/store'
import { loadAudio } from '~/audio/api.native'

const cached = {}

export const AUDIO = {}

export function AUDIO_STOP_ALL() {
    Object.values(cached).forEach((audio) => {
        audio.sound.stopAsync().catch(() => {})
    })
}

function createAudio(
    name,
    {
        cache = true,
        multiple = true,
        isLooping = false,
        volume = 0.5,
        filter,
    } = {}
) {
    filter =
        filter ||
        (() => {
            const { sounds } = getGlobalState()
            return sounds
        })

    return async function (play = true) {
        if (multiple /*|| !cache*/ || !cached.hasOwnProperty(name)) {
            cached[name] = await loadAudio(AUDIOS[name], {
                volume,
                isLooping,
            })
        }

        if (play && filter()) {
            if (multiple) {
                cached[name].sound.playAsync().catch(() => {})
            } else {
                const status = await cached[name].sound.getStatusAsync()
                if (!status.isPlaying) {
                    cached[name].sound.playAsync().catch(() => {})
                }
            }
        } else if (cached.hasOwnProperty(name)) {
            cached[name].sound.stopAsync().catch(() => {})
        }

        return cached[name]
    }
}

const options = {
    CLICK: {
        volume: 0.3,
    },
    OPEN: {
        volume: 0.3,
    },
    CLOSE: {
        volume: 0.2,
    },
    NOTIFICATION: {
        volume: 0.4,
    },
    TIMER: {
        cache: false,
        volume: 0.3,
    },
    MATCH_FOUND: {
        volume: 0.2,
    },
    VICTORY: {
        volume: 0.1,
    },
    DEFEAT: {
        volume: 0.1,
    },

    MUSIC: {
        multiple: false,
        isLooping: true,
        volume: 0.2,
        filter: () => true,
    },
}

Object.keys(AUDIOS).forEach((name) => {
    AUDIO[name] = createAudio(name, options[name])
})

window.AUDIO = AUDIO
window.AUDIO_STOP_ALL = AUDIO_STOP_ALL
