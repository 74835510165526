import React from 'react'
import styled from '@emotion/native'
import { CARDS, REWARD_ASSET, SHOP_METHOD } from 'conkis-core'
import { px } from '~/utils/device'
import { COLOR, CARD_SIZE } from '~/const'
import { CARD_ASSETS } from '~/const/assets'
import { useLocalState } from '~/store/hooks'
import { Server } from '~/server'
import { translate } from '~/locale'
import { AUDIO } from '~/audio'
import DialogShop from '~/components/stateless/DialogShop'
import Loading from '~/components/stateless/Loading'
import Card from '~/components/stateless/Card'
import Button from '~/components/stateless/Button'
import RewardIcon from '~/components/stateless/RewardIcon'
import { Error, ErrorText } from '~/components/stateless/Error'

export default function ShopItem({
    item,
    user,
    cards,
    onCardInfo,
    onPayment,
    onClose,
}) {
    const [state, setState] = useLocalState({
        purchasing: false,
        purchased: false,
        error: null,
    })

    const cards_owned = cards.filter(
        (c) => c.rarity === item.rarity && c.type === item.type
    )

    function onBuyTulips(item) {
        AUDIO.CLICK()
        onBuy({ method: SHOP_METHOD.TULIPS, shop_id: item.id })
    }

    function onBuyCoins(item) {
        AUDIO.CLICK()
        onBuy({ method: SHOP_METHOD.COINS, shop_id: item.id })
    }

    async function onBuy({ method, shop_id }) {
        setState({ purchasing: true })
        try {
            await Server.shopPurchase({ method, shop_id })
            AUDIO.SHOP()
            setState({ purchasing: false, purchased: true })
        } catch (e) {
            setState({ error: e.error, purchasing: false })
        }
    }

    return (
        <DialogShop onClose={state.purchasing ? null : onClose}>
            <Content>
                <Top>
                    <TitleContainer>
                        <Title>
                            {item.asset === REWARD_ASSET.CARD
                                ? translate(CARDS[item.type].title)
                                : `x${item.amount} ${
                                      item.asset === REWARD_ASSET.TULIPS
                                          ? translate('Tulips')
                                          : translate('Coins')
                                  }`}
                        </Title>
                    </TitleContainer>
                    <ItemContainer>
                        {item.asset === REWARD_ASSET.CARD ? (
                            <Card
                                scale={1.5}
                                power={item.power}
                                rarity={item.rarity}
                                source={
                                    CARD_ASSETS[
                                        item.rarity + item.type.toLowerCase()
                                    ]
                                }
                                onClick={() => onCardInfo(item)}
                            />
                        ) : (
                            <RewardIcon
                                size={CARD_SIZE.HEIGHT * 1.5}
                                asset={item.asset}
                            />
                        )}
                    </ItemContainer>
                    <Tags>
                        {!state.purchased && (
                            <>
                                {item.offer !== undefined && (
                                    <>
                                        <TagsContainer>
                                            <TagsText>
                                                {`-${item.offer.off}%`}
                                            </TagsText>
                                        </TagsContainer>

                                        {/* <TagsContainer>
                                        <TagsText>
                                            {translate(
                                                '${h}h ${m}m',
                                                secondsToTimeRaw(
                                                    (item.offer.ends -
                                                        item.offer.now) /
                                                        1000
                                                )
                                            )}
                                        </TagsText>
                                    </TagsContainer> */}
                                    </>
                                )}
                                {item.asset === REWARD_ASSET.CARD && (
                                    <TagsContainer>
                                        <TagsText>
                                            {translate(
                                                'Cards you have of this kind: ${number}',
                                                {
                                                    number: cards_owned.length,
                                                }
                                            )}
                                        </TagsText>
                                    </TagsContainer>
                                )}
                                {item.asset === REWARD_ASSET.TULIPS && (
                                    <>
                                        <TagsContainer>
                                            <TagsText>
                                                {translate('Bonus: ${number}', {
                                                    number: item.bonus,
                                                })}
                                            </TagsText>
                                        </TagsContainer>
                                        <TagsContainer>
                                            <TagsText>
                                                {translate(
                                                    'Tulips after purchasing: ${number}',
                                                    {
                                                        number:
                                                            user.tulips +
                                                            item.amount,
                                                    }
                                                )}
                                            </TagsText>
                                        </TagsContainer>
                                    </>
                                )}
                                {item.asset === REWARD_ASSET.COINS && (
                                    <>
                                        <TagsContainer>
                                            <TagsText>
                                                {translate('Bonus: ${number}', {
                                                    number: item.bonus,
                                                })}
                                            </TagsText>
                                        </TagsContainer>
                                        <TagsContainer>
                                            <TagsText>
                                                {translate(
                                                    'Coins after purchasing: ${number}',
                                                    {
                                                        number:
                                                            user.coins +
                                                            item.amount,
                                                    }
                                                )}
                                            </TagsText>
                                        </TagsContainer>
                                    </>
                                )}
                            </>
                        )}
                    </Tags>
                </Top>

                {state.error !== null && (
                    <Bottom>
                        <BottomError>
                            <Error>
                                <ErrorText>
                                    {translate(
                                        'An error occurred during the purchase. Try again reloading the shop.'
                                    )}
                                </ErrorText>
                                <ErrorText bold={true}>
                                    {translate('Error: ${error}', {
                                        error: state.error,
                                    })}
                                </ErrorText>
                            </Error>
                        </BottomError>
                    </Bottom>
                )}

                {state.purchasing && (
                    <Bottom>
                        <Loading />
                    </Bottom>
                )}

                {state.purchased && (
                    <Bottom>
                        <ButtonContainer>
                            <ButtonTop>
                                <Button
                                    label={translate('Continue')}
                                    color={COLOR.BLUEDARK}
                                    width={300}
                                    height={70}
                                    fontSize={35}
                                    iconSize={40}
                                    onClick={onClose}
                                />
                            </ButtonTop>
                            <ButtonBottom>
                                <ButtonBottomText opacity="1" bold={true}>
                                    {translate('Purchase completed')}!
                                </ButtonBottomText>
                            </ButtonBottom>
                        </ButtonContainer>
                    </Bottom>
                )}

                {state.error === null &&
                    !state.purchasing &&
                    !state.purchased && (
                        <Bottom>
                            {item.tulips !== undefined && (
                                <ButtonContainer>
                                    <ButtonTop>
                                        <Button
                                            label={item.tulips}
                                            disabled={item.tulips > user.tulips}
                                            width={200}
                                            height={65}
                                            fontSize={35}
                                            icon="icon-tulip.png"
                                            iconSize={40}
                                            onClick={() => onBuyTulips(item)}
                                        />
                                    </ButtonTop>
                                    <ButtonBottom>
                                        {!(item.tulips > user.tulips) && (
                                            <ButtonBottomText>
                                                {translate('New balance') +
                                                    ': ' +
                                                    (user.tulips - item.tulips)}
                                            </ButtonBottomText>
                                        )}
                                    </ButtonBottom>
                                </ButtonContainer>
                            )}
                            {item.coins !== undefined && (
                                <ButtonContainer>
                                    <ButtonTop>
                                        <Button
                                            label={item.coins}
                                            disabled={item.coins > user.coins}
                                            width={200}
                                            height={65}
                                            fontSize={35}
                                            icon="icon-coin.png"
                                            iconSize={40}
                                            onClick={() => onBuyCoins(item)}
                                        />
                                    </ButtonTop>
                                    <ButtonBottom>
                                        {!(item.coins > user.coins) && (
                                            <ButtonBottomText>
                                                {translate('New balance') +
                                                    ': ' +
                                                    (user.coins - item.coins)}
                                            </ButtonBottomText>
                                        )}
                                    </ButtonBottom>
                                </ButtonContainer>
                            )}
                            {item.usd !== undefined && (
                                <ButtonContainer>
                                    <ButtonTop>
                                        <Button
                                            label={item.usd}
                                            width={200}
                                            height={65}
                                            fontSize={35}
                                            icon="icon-dollar.png"
                                            iconSize={40}
                                            onClick={() => onPayment(item)}
                                        />
                                    </ButtonTop>
                                    <ButtonBottom>
                                        <ButtonBottomText></ButtonBottomText>
                                    </ButtonBottom>
                                </ButtonContainer>
                            )}
                        </Bottom>
                    )}
            </Content>
        </DialogShop>
    )
}

const Content = styled.View`
    justify-content: center;
    align-items: center;
    flex: 1;
`
const Top = styled.View`
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${px(600)};
`
const Bottom = styled.View`
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`
const TitleContainer = styled.View`
    justify-content: center;
    margin-bottom: ${px(30)};
`
const Title = styled.Text`
    font-family: Poppins-Black;
    color: ${COLOR.BROWNDARK};
    font-size: ${px(30)};
    text-align: center;
`

const BottomError = styled.View`
    flex: 1;
`
const ButtonContainer = styled.View`
    margin: 0 ${px(20)};
`
const ButtonTop = styled.View``
const ButtonBottom = styled.View`
    margin-top: ${px(20)};
    height: ${px(30)};
    align-items: center;
`
const ButtonBottomText = styled.Text`
    font-size: ${px(18)};
    color: ${COLOR.BROWN};
    opacity: ${(p) => p.opacity || '0.4'};
    font-family: ${(p) => (p.bold ? 'Poppins-Bold' : 'Poppins-Medium')};
`
const ItemContainer = styled.View`
    // background: blue;
    margin-bottom: ${px(20)};
`
const Tags = styled.View`
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: ${px(30)};
`
const TagsContainer = styled.View`
    border-radius: ${px(30)};
    padding: ${px(5)} ${px(20)};
    margin: 0 ${px(5)};
    // border: ${px(3)} solid ${COLOR.BROWN + '28'};
    background: ${COLOR.BROWN + '28'};
    justify-content: center;
    align-items: center;
`
const TagsText = styled.Text`
    font-family: Poppins-Medium;
    color: ${COLOR.BROWN};
    font-size: ${px(15)};
    opacity: 0.8;
`
