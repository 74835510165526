import React, { useEffect, useCallback } from 'react'
import styled from '@emotion/native'
import { UX_ASSETS, PROFILE_ICONS } from '~/const/assets'
import { VIEW, COLOR, PADDING } from '~/const'
import { AUDIO } from '~/audio'
import { changeView } from '~/store'
import { secondsToTime, timeAgo } from '~/utils'
import { useLocalState } from '~/store/hooks'
import { Server } from '~/server'
import { translate } from '~/locale'
import { px } from '~/utils/device'
import { AnimationBlink } from '~/components/Animations'
import Lobby from '~/components/partials/Lobby'
import {
    Table,
    TableHeader,
    TableHeaderText,
    TableList,
    TableScroll,
    TableScrollBorder,
    TableRow,
    TableCol,
    TableContent,
    TableContentTouchable,
    TableText,
} from '~/components/stateless/Table'

export default function LiveGames() {
    return (
        <Lobby title={translate('Live Games')} back={VIEW.HOME}>
            <ScrollView>
                <Content>
                    <Sides>
                        <Left>
                            <LiveGamesTable />
                        </Left>
                        <Right>
                            <LatestGamesTable />
                        </Right>
                    </Sides>
                </Content>
            </ScrollView>
        </Lobby>
    )
}

function LiveGamesTable() {
    const [state, setState] = useLocalState({
        page: 1,
        page_max: 10,
        games_total: 0,
        games: [],
    })

    const has_prev = state.page > 1
    const has_next = state.page * state.page_max < state.games_total
    const games = state.games.slice(0)
    games.length = state.page_max
    games.fill(null, state.games.length)

    useEffect(() => {
        const timer = setInterval(() => {
            fetchGames(state.page)
        }, 1000)
        return () => {
            clearInterval(timer)
        }
    }, [state.page])

    async function fetchGames(page) {
        const { games, games_total, page_max } = await Server.livegamesLive({
            page,
        })
        setState({ games, games_total, page_max })
    }

    function onPrev() {
        AUDIO.CLICK()
        const page = state.page - 1
        setState({ page })
        fetchGames(page)
    }

    function onNext() {
        AUDIO.CLICK()
        const page = state.page + 1
        setState({ page })
        fetchGames(page)
    }

    return (
        <Table>
            <TableHeader>
                <AnimationBlink>
                    <LiveIcon />
                </AnimationBlink>
                <TableHeaderText>{translate('Live Games')}</TableHeaderText>
            </TableHeader>
            <TableList>
                <TableScroll>
                    {games.map((game, index) => (
                        <TableRow
                            key={index}
                            disabled={true}
                            height={85}
                            paddingRight={1}
                            background={COLOR.BROWNBLACK2}
                        >
                            <TableCol width="100%">
                                {game === null ? null : (
                                    <GameRow
                                        userid1={game.userid1}
                                        userid2={game.userid2}
                                        username1={game.username1}
                                        username2={game.username2}
                                        icon1={game.icon1}
                                        icon2={game.icon2}
                                        trophies1={game.trophies1}
                                        trophies2={game.trophies2}
                                        livegame={game.livegame}
                                        stats={[
                                            {
                                                label: 'Units',
                                                value: `${game.units1} - ${game.units2}`,
                                            },
                                            {
                                                label: 'Flags',
                                                value: `${game.flags1} - ${game.flags2}`,
                                            },
                                            {
                                                label: 'Power',
                                                value: `${game.power1} - ${game.power2}`,
                                                width: 90,
                                            },
                                            {
                                                label: 'Round',
                                                value: game.rounds,
                                            },
                                            {
                                                label: 'Time',
                                                value: secondsToTime(
                                                    Math.round(game.time / 1000)
                                                ),
                                            },
                                        ]}
                                    />
                                )}
                            </TableCol>
                        </TableRow>
                    ))}
                </TableScroll>

                <TablePagination
                    state={state}
                    onPrev={has_prev ? onPrev : null}
                    onNext={has_next ? onNext : null}
                />
            </TableList>
        </Table>
    )
}

function LatestGamesTable() {
    const [state, setState] = useLocalState({
        page: 1,
        page_max: 10,
        games_total: 0,
        games: [],
    })

    const has_prev = state.page > 1
    const has_next = state.page * state.page_max < state.games_total
    const games = state.games.slice(0)
    games.length = state.page_max
    games.fill(null, state.games.length)

    useEffect(() => {
        const timer = setInterval(() => {
            fetchGames(state.page)
        }, 10000)
        return () => {
            clearInterval(timer)
        }
    }, [state.page])

    useEffect(() => {
        fetchGames(1)
    }, [])

    async function fetchGames(page) {
        const { games, games_total, page_max } = await Server.livegamesLatest({
            page,
        })
        setState({ games, games_total, page_max })
    }

    function onPrev() {
        AUDIO.CLICK()
        const page = state.page - 1
        setState({ page })
        fetchGames(page)
    }

    function onNext() {
        AUDIO.CLICK()
        const page = state.page + 1
        setState({ page })
        fetchGames(page)
    }

    return (
        <Table background={COLOR.BROWNBLACK2 + '99'}>
            {/* <Table background={COLOR.GREENBLACK}> */}
            <TableHeader>
                <TableHeaderText>{translate('Latest Games')}</TableHeaderText>
            </TableHeader>
            <TableList>
                <TableScroll>
                    {games.map((game, index) => (
                        <TableRow
                            disabled={true}
                            height={85}
                            paddingRight={1}
                            key={index}
                        >
                            <TableCol width="100%">
                                {game === null ? null : (
                                    <GameRow
                                        userid1={game.userid1}
                                        userid2={game.userid2}
                                        username1={game.username1}
                                        username2={game.username2}
                                        icon1={game.icon1}
                                        icon2={game.icon2}
                                        trophies1={game.trophies1}
                                        trophies2={game.trophies2}
                                        trophieswin1={game.trophieswin1}
                                        trophieswin2={game.trophieswin2}
                                        stats={[
                                            {
                                                label: 'By',
                                                value: game.reason.slice(0, 6),
                                            },
                                            {
                                                label: 'Units',
                                                value: `${game.units1} - ${game.units2}`,
                                            },
                                            {
                                                label: 'Flags',
                                                value: `${game.flags1} - ${game.flags2}`,
                                            },
                                            {
                                                label: 'Rounds',
                                                value: game.rounds,
                                            },
                                            {
                                                label: 'Durat.',
                                                value: secondsToTime(
                                                    game.time / 1000
                                                ),
                                            },
                                            {
                                                label: 'Time ago',
                                                value: timeAgo(game.created),
                                                width: 90,
                                            },
                                        ]}
                                    />
                                )}
                            </TableCol>
                        </TableRow>
                    ))}
                </TableScroll>

                <TablePagination
                    state={state}
                    onPrev={has_prev ? onPrev : null}
                    onNext={has_next ? onNext : null}
                />
            </TableList>
        </Table>
    )
}

function GameRow({
    userid1,
    userid2,
    username1 = generateRandomName(),
    username2 = generateRandomName(),
    icon1,
    icon2,
    trophies1 = 2314,
    trophies2 = 4512,
    trophieswin1,
    trophieswin2,
    livegame,
    stats = [],
}) {
    const playercolor1 =
        icon1 !== undefined || (trophieswin1 === 0 && trophieswin2 === 0)
            ? COLOR.BEIGE4
            : trophieswin1 > trophieswin2
            ? COLOR.GREEN
            : COLOR.RED
    const playercolor2 =
        icon2 !== undefined || (trophieswin2 === 0 && trophieswin1 === 0)
            ? COLOR.BEIGE4
            : trophieswin2 > trophieswin1
            ? COLOR.GREEN
            : COLOR.RED
    return (
        <GameRowContainer>
            <GameRowContainer2>
                <GameRightLeft>
                    <GamePlayerContainer
                        onPress={() => {
                            AUDIO.OPEN()
                            changeView(VIEW.PROFILE, {
                                profile_id: userid1,
                                viewback: VIEW.LIVEGAMES,
                            })
                        }}
                    >
                        <GameIconUserContainer>
                            <GameIconUser
                                color={playercolor1}
                                source={
                                    trophieswin1 === undefined
                                        ? PROFILE_ICONS[icon1]
                                        : null
                                }
                            />
                        </GameIconUserContainer>
                        <GamePlayerName>{username1}</GamePlayerName>
                        <GamePlayerTrophiesIcon
                            source={UX_ASSETS['icon-trophy2.png']}
                        />
                        <GamePlayerTrophiesValue>
                            {trophies1}
                        </GamePlayerTrophiesValue>
                        {(trophieswin1 > 0 || trophieswin1 < 0) && (
                            <GamePlayerTrophiesValue color={playercolor1}>
                                {trophieswin1 >= 0 ? '+' : ''}
                                {trophieswin1}
                            </GamePlayerTrophiesValue>
                        )}
                    </GamePlayerContainer>
                    <GamePlayerContainer
                        onPress={() => {
                            AUDIO.OPEN()
                            changeView(VIEW.PROFILE, {
                                profile_id: userid2,
                                viewback: VIEW.LIVEGAMES,
                            })
                        }}
                    >
                        <GameIconUserContainer>
                            <GameIconUser
                                color={playercolor2}
                                source={
                                    trophieswin2 === undefined
                                        ? PROFILE_ICONS[icon2]
                                        : null
                                }
                            />
                        </GameIconUserContainer>
                        <GamePlayerName>{username2}</GamePlayerName>
                        <GamePlayerTrophiesIcon
                            source={UX_ASSETS['icon-trophy2.png']}
                        />
                        <GamePlayerTrophiesValue>
                            {trophies2}
                        </GamePlayerTrophiesValue>
                        {(trophieswin2 > 0 || trophieswin2 < 0) && (
                            <GamePlayerTrophiesValue color={playercolor2}>
                                {trophieswin2 >= 0 ? '+' : ''}
                                {trophieswin2}
                            </GamePlayerTrophiesValue>
                        )}
                    </GamePlayerContainer>
                </GameRightLeft>
                <GameRightRight>
                    {stats.map(({ label, value, width }, index) => (
                        <GameRightCol width={width} key={index}>
                            <GamePropertyMedium>{label}</GamePropertyMedium>
                            <GamePropertySmall>{value}</GamePropertySmall>
                        </GameRightCol>
                    ))}

                    {livegame !== undefined && (
                        <ObserveButtonContainer
                            onPress={() => {
                                AUDIO.OPEN()
                                changeView(VIEW.GAME, {
                                    game_id: livegame,
                                })
                            }}
                        >
                            <ObserveButton>
                                <ObserveButtonIcon
                                    source={UX_ASSETS['icon-play.png']}
                                />
                            </ObserveButton>
                        </ObserveButtonContainer>
                    )}
                </GameRightRight>
            </GameRowContainer2>
        </GameRowContainer>
    )
}

function TablePagination({ state, onPrev, onNext }) {
    const hasprev = !(typeof onPrev === 'function')
    const hasnext = !(typeof onNext === 'function')
    return (
        <TableScrollBorder>
            <TableRow disabled={true}>
                <TableCol width="70%">
                    {state !== undefined &&
                        state.games_total > state.page_max && (
                            <TableContent align="flex-end">
                                <TableText opacity="0.2">
                                    {`${state.page} / ${
                                        state.games_total / state.page_max
                                    }`}
                                </TableText>
                            </TableContent>
                        )}
                </TableCol>
                <TableCol width="15%">
                    <TableContent align="flex-end">
                        <TableContentTouchable
                            disabled={hasprev}
                            onPress={onPrev}
                        >
                            <TableText opacity={hasprev ? '0.2' : '1'}>
                                ◄ {translate('Prev')}
                            </TableText>
                        </TableContentTouchable>
                    </TableContent>
                </TableCol>

                <TableCol width="15%">
                    <TableContent align="flex-end">
                        <TableContentTouchable
                            disabled={hasnext}
                            onPress={onNext}
                        >
                            <TableText opacity={hasnext ? '0.2' : '1'}>
                                {translate('Next')} ►
                            </TableText>
                        </TableContentTouchable>
                    </TableContent>
                </TableCol>
            </TableRow>
        </TableScrollBorder>
    )
}

function generateRandomName() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
    const nameLength = Math.floor(Math.random() * (20 - 3 + 1)) + 3
    let randomName = ''
    for (let i = 0; i < nameLength; i++) {
        randomName += characters.charAt(
            Math.floor(Math.random() * characters.length)
        )
    }
    return randomName
}

const ScrollView = styled.ScrollView``

const Content = styled.View`
    padding: ${px(PADDING.SIDES / 3)} ${px(PADDING.SIDES)};
`

const Sides = styled.View`
    flex-direction: row;
    justify-content: space-between;
    padding-top: ${px(0)};
`

const Left = styled.View`
    width: 49%;
`
const Right = styled.View`
    width: 49%;
`

// TouchableOpacity
const GameRowContainer = styled.View`
    width: 100%;
    height: 100%;
    flex-direction: row;
`
const GameRowContainer2 = styled.View`
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
`
const GameRightLeft = styled.View`
    flex: 1;
`
const GameRightRight = styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: center;
`
const GameRightCol = styled.View`
    align-items: center;
    border: ${px(1)} dashed ${COLOR.BEIGE + '22'};
    border-right-width: 0;
    border-top-width: 0;
    border-bottom-width: 0;
    width: ${(p) => px(p.width || 70)};
    // width: ${(p) => (p.width != undefined ? px(p.width) : px(70))};
    // padding-left: ${px(12)};
    // padding-right: ${px(12)};
`

const ObserveButtonContainer = styled.TouchableOpacity`
    align-items: center;
    justify-content: center;
    padding-right: ${px(15)};
    padding-left: ${px(15)};
    border: ${px(1)} dashed ${COLOR.BEIGE + '22'};
    border-right-width: 0;
    border-top-width: 0;
    border-bottom-width: 0;
`
const ObserveButton = styled.View`
    align-items: center;
    justify-content: center;
    background: ${COLOR.GREENDARK};
    height: ${px(40)};
    width: ${px(40)};
    border-radius: ${px(3)};
`
const ObserveButtonIcon = styled.Image`
    height: ${px(14)};
    width: ${px(14)};
    opacity: 0.9;
`

const GamePropertyMedium = styled.Text`
    font-family: Poppins-Medium;
    font-size: ${px(14)};
    // line-height: ${px(16)};
    color: ${COLOR.BEIGE3};
`
const GamePropertySmall = styled.Text`
    font-family: Poppins-Bold;
    font-size: ${px(15)};
    // line-height: ${px(17)};
    color: ${COLOR.BEIGE};
`

const GamePlayerContainer = styled.TouchableOpacity`
    flex-direction: row;
    display: flex;
    align-items: center;
    margin: ${px(5)} 0;
`

const GameIconUserContainer = styled.View`
    justify-content: center;
    margin-right: ${px(10)};
`

const GameIconUser = styled.Image`
    width: ${px(24)};
    height: ${px(24)};
    border-radius: 50%;
    border: ${px(2)} solid ${COLOR.BEIGE};
    background: ${(p) => p.color || '#999999'};
`

const GamePlayerName = styled.Text`
    font-family: Poppins-Medium;
    font-size: ${px(18)};
    line-height: ${px(20)};
    color: ${COLOR.BEIGE};
`

const GamePlayerTrophiesIcon = styled.Image`
    width: ${px(12)};
    height: ${px(12)};
    margin-left: ${px(10)};
`
const GamePlayerTrophiesValue = styled.Text`
    font-family: Poppins-SemiBold;
    color: ${(p) => p.color || COLOR.BEIGE4};
    font-size: ${px(16)};
    line-height: ${px(20)};
    margin-left: ${px(3)};
`

const LiveIcon = styled.View`
    width: ${px(23)};
    height: ${px(23)};
    margin-right: ${px(15)};
    background: red;
    border-radius: 50%;
`
