import React, { useEffect } from 'react'
import styled from '@emotion/native'
import { REWARD_ASSET } from 'conkis-core'
import { UX_ASSETS, PROFILE_ICONS } from '~/const/assets'
import { VIEW, COLOR, PADDING } from '~/const'
import { AUDIO } from '~/audio'
import { changeView } from '~/store'
import { useLocalState } from '~/store/hooks'
import { Server } from '~/server'
import { translate } from '~/locale'
import { px } from '~/utils/device'
import Lobby from '~/components/partials/Lobby'
import {
    TabsHeader,
    Tabs,
    Tab,
    TabText,
    TabsInfo,
    TabsInfoText,
} from '~/components/stateless/Tabs'
import {
    Table,
    TableHeader,
    TableHeaderText,
    TableList,
    TableScroll,
    TableScrollBorder,
    TableRow,
    TableCol,
    TableContent,
    TableContentTouchable,
    TableText,
} from '~/components/stateless/Table'

const LEADERBOARD = {
    WEEKLY: 'WEEKLY',
    SEASON: 'SEASON',
}

export default function Leaderboard() {
    const [state, setState] = useLocalState({
        leaderboard: LEADERBOARD.WEEKLY,
    })

    function onChangeLeaderboard({ leaderboard }) {
        setState({ leaderboard })
    }

    return (
        <>
            <Lobby title={translate('Leaderboard')} back={VIEW.HOME}>
                <ScrollView>
                    <Content>
                        <TabsHeader>
                            <Tabs>
                                <Tab
                                    selected={
                                        state.leaderboard === LEADERBOARD.WEEKLY
                                    }
                                    onPress={() => {
                                        AUDIO.OPEN()
                                        onChangeLeaderboard({
                                            leaderboard: LEADERBOARD.WEEKLY,
                                        })
                                    }}
                                >
                                    <TabText
                                        selected={
                                            state.leaderboard ===
                                            LEADERBOARD.WEEKLY
                                        }
                                    >
                                        {translate('Weekly')}
                                    </TabText>
                                </Tab>
                                <Tab
                                    selected={
                                        state.leaderboard === LEADERBOARD.SEASON
                                    }
                                    onPress={() => {
                                        AUDIO.OPEN()
                                        onChangeLeaderboard({
                                            leaderboard: LEADERBOARD.SEASON,
                                        })
                                    }}
                                >
                                    <TabText
                                        selected={
                                            state.leaderboard ===
                                            LEADERBOARD.SEASON
                                        }
                                    >
                                        {translate('Season')}
                                    </TabText>
                                </Tab>
                            </Tabs>
                        </TabsHeader>

                        {state.leaderboard === LEADERBOARD.WEEKLY && (
                            <LeaderboardWeekly />
                        )}

                        {state.leaderboard === LEADERBOARD.SEASON && (
                            <LeaderboardSeason />
                        )}

                        <Footer>
                            <InfoText width="50%">
                                {translate(
                                    'The use of multiple accounts is permitted; however, using them to boost the main account is strictly prohibited. Penalties will be applied if such behavior is detected.'
                                )}
                            </InfoText>
                        </Footer>
                    </Content>
                </ScrollView>
            </Lobby>
        </>
    )
}

function LeaderboardWeekly() {
    const [state, setState] = useLocalState({
        rewards: [],
        page: 1,
        page_max: 10,
        page_players: 10,
        players: [],
        players_total: 0,
        player: { cards: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 } },
    })

    const players = state.players.slice(0)
    players.length = state.page_players
    players.fill(null, state.players.length)

    const disable_prev = state.page === 1
    const disable_next =
        state.page >= state.page_max ||
        state.page * state.page_players > state.players_total

    useEffect(() => {
        getLeaderboard({ page: 1, fetch: true })
    }, [])

    async function getLeaderboard({ page, fetch = false }) {
        const result = await Server.leaderboardWeekly({ page, fetch })
        setState({ page, ...result })
    }

    function onNext() {
        AUDIO.CLICK()
        getLeaderboard({ page: state.page + 1 })
    }

    function onPrev() {
        AUDIO.CLICK()
        getLeaderboard({ page: state.page - 1 })
    }

    return (
        <Sides>
            <List>
                <Table>
                    <TableHeader>
                        <TableHeaderText>
                            {translate('Weekly Leaderboard')}
                        </TableHeaderText>
                    </TableHeader>
                    <TableList>
                        <TableScrollBorder>
                            <TableRow
                                disabled={true}
                                background={COLOR.BROWNBLACK2}
                            >
                                <TableCol width="10%">
                                    <TableContent>
                                        <TableText opacity={'0.5'} bold={true}>
                                            {translate('Pos')}
                                        </TableText>
                                    </TableContent>
                                </TableCol>
                                <TableCol width="60%">
                                    <TableContent>
                                        <TableText opacity={'0.5'} bold={true}>
                                            {translate('Player')}
                                        </TableText>
                                    </TableContent>
                                </TableCol>
                                <TableCol width="15%">
                                    <TableContent align="center">
                                        <TableText opacity={'0.5'} bold={true}>
                                            W / D / L
                                        </TableText>
                                    </TableContent>
                                </TableCol>
                                <TableCol width="15%">
                                    <TableContent align="flex-end">
                                        <TableText opacity={'0.5'} bold={true}>
                                            {translate('Score')}
                                        </TableText>
                                    </TableContent>
                                </TableCol>
                            </TableRow>
                        </TableScrollBorder>

                        <TableScroll>
                            {players.map((player, index) => (
                                <PlayerRow
                                    player={player}
                                    score="score"
                                    key={index}
                                />
                            ))}
                        </TableScroll>

                        <TableScrollBorder>
                            <PlayerRow
                                player={state.player}
                                score="score"
                                background={COLOR.GREENDARK}
                            />
                        </TableScrollBorder>

                        <TableScrollBorder>
                            <TableRow disabled={true}>
                                <TableCol width="70%"></TableCol>
                                <TableCol width="15%">
                                    <TableContent align="flex-end">
                                        <TableContentTouchable
                                            disabled={disable_prev}
                                            onPress={onPrev}
                                        >
                                            <TableText
                                                opacity={
                                                    disable_prev ? '0.2' : '1'
                                                }
                                            >
                                                ◄ {translate('Prev')}
                                            </TableText>
                                        </TableContentTouchable>
                                    </TableContent>
                                </TableCol>
                                <TableCol width="15%">
                                    <TableContent align="flex-end">
                                        <TableContentTouchable
                                            disabled={disable_next}
                                            onPress={onNext}
                                        >
                                            <TableText
                                                opacity={
                                                    disable_next ? '0.2' : '1'
                                                }
                                            >
                                                {translate('Next')} ►
                                            </TableText>
                                        </TableContentTouchable>
                                    </TableContent>
                                </TableCol>
                            </TableRow>
                        </TableScrollBorder>
                    </TableList>
                </Table>
            </List>
            <Info>
                <Table background={COLOR.BROWNBLACK2 + '88'}>
                    <InfoTop>
                        <TableHeader>
                            <TableHeaderText>
                                {translate('Rewards')}
                            </TableHeaderText>
                        </TableHeader>
                        <InfoRewards>
                            <TableScrollBorder>
                                {state.rewards.map((reward, index) => {
                                    const rank =
                                        reward.rank.length === 1
                                            ? `#${reward.rank[0]}`
                                            : `#${reward.rank[0]} - #${reward.rank[1]}`

                                    return (
                                        <TableRow disabled={true} key={index}>
                                            <TableCol width="20%">
                                                <TableText>{rank}</TableText>
                                            </TableCol>
                                            <TableCol width="80%">
                                                <TableContent align="flex-end">
                                                    {reward.rewards.map(
                                                        (reward, index) => (
                                                            <InfoRewardItem
                                                                key={index}
                                                            >
                                                                <Icon
                                                                    size={30}
                                                                    source={
                                                                        UX_ASSETS[
                                                                            `icon-${formatRewardIcon(
                                                                                reward
                                                                            )}.png`
                                                                        ]
                                                                    }
                                                                />
                                                                <InfoRewardText>
                                                                    {formatReward(
                                                                        reward
                                                                    )}
                                                                </InfoRewardText>
                                                            </InfoRewardItem>
                                                        )
                                                    )}
                                                </TableContent>
                                            </TableCol>
                                        </TableRow>
                                    )
                                })}
                            </TableScrollBorder>
                        </InfoRewards>
                    </InfoTop>
                    <InfoBottom>
                        {state.rewards_date !== undefined && (
                            <>
                                <Footer>
                                    <InfoText>
                                        {translate(
                                            'This leaderboard resets on:'
                                        )}
                                    </InfoText>
                                </Footer>
                                <TabsInfo>
                                    <TabsInfoText>
                                        {new Date(
                                            Date.UTC(
                                                state.rewards_date.year,
                                                state.rewards_date.month,
                                                state.rewards_date.day
                                            )
                                        ).toGMTString()}
                                    </TabsInfoText>
                                </TabsInfo>

                                <Footer>
                                    <InfoText size={16}>
                                        {translate(
                                            'Only matches against real players affect the score. New accounts must secure ${min} wins against bots before being matched with real players.',
                                            { min: state.info.min_games }
                                        )}
                                    </InfoText>
                                </Footer>

                                <Footer>
                                    <InfoText size={16}>
                                        {translate(
                                            'Score = Wins*${WINS} + Draws*${DRAWS} + Loses*${LOSES}',
                                            state.info.multiply
                                        )}
                                    </InfoText>
                                </Footer>
                            </>
                        )}
                    </InfoBottom>
                </Table>
            </Info>
        </Sides>
    )
}

function LeaderboardSeason() {
    const [state, setState] = useLocalState({
        rewards: [],
        page: 1,
        page_max: 50,
        page_players: 10,
        players: [],
        players_total: 0,
        player: { cards: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 } },
    })

    const players = state.players.slice(0)
    players.length = state.page_players
    players.fill(null, state.players.length)

    const disable_prev = state.page === 1
    const disable_next =
        state.page >= state.page_max ||
        state.page * state.page_players > state.players_total

    useEffect(() => {
        getLeaderboard({ page: 1, fetch: true })
    }, [])

    async function getLeaderboard({ page, fetch = false }) {
        const result = await Server.leaderboardSeason({ page, fetch })
        setState({ page, ...result })
    }

    function onNext() {
        AUDIO.CLICK()
        getLeaderboard({ page: state.page + 1 })
    }

    function onPrev() {
        AUDIO.CLICK()
        getLeaderboard({ page: state.page - 1 })
    }

    return (
        <Sides>
            <List>
                <Table>
                    <TableHeader>
                        <TableHeaderText>
                            {translate('Season Leaderboard')}
                        </TableHeaderText>
                    </TableHeader>
                    <TableList>
                        <TableScrollBorder>
                            <TableRow
                                disabled={true}
                                background={COLOR.BROWNBLACK2}
                            >
                                <TableCol width="10%">
                                    <TableContent>
                                        <TableText opacity={'0.5'} bold={true}>
                                            {translate('Pos')}
                                        </TableText>
                                    </TableContent>
                                </TableCol>
                                <TableCol width="60%">
                                    <TableContent>
                                        <TableText opacity={'0.5'} bold={true}>
                                            {translate('Player')}
                                        </TableText>
                                    </TableContent>
                                </TableCol>
                                <TableCol width="15%">
                                    <TableContent align="center">
                                        <TableText opacity={'0.5'} bold={true}>
                                            W / D / L
                                        </TableText>
                                    </TableContent>
                                </TableCol>
                                <TableCol width="15%">
                                    <TableContent align="flex-end">
                                        <TableText opacity={'0.5'} bold={true}>
                                            {translate('Trophies')}
                                        </TableText>
                                    </TableContent>
                                </TableCol>
                            </TableRow>
                        </TableScrollBorder>

                        <TableScroll>
                            {players.map((player, index) => (
                                <PlayerRow
                                    player={player}
                                    score="trophies"
                                    key={index}
                                />
                            ))}
                        </TableScroll>

                        <TableScrollBorder>
                            <PlayerRow
                                player={state.player}
                                score="trophies"
                                background={COLOR.GREENDARK}
                            />
                            {/* <TableRow >
                                <TableCol width="10%">
                                    <TableContent>
                                        <TableText>
                                            {state.player_rank === undefined
                                                ? '-'
                                                : `#${state.player_rank}`}
                                        </TableText>
                                    </TableContent>
                                </TableCol>
                                <TableCol width="60%">
                                    <TableContent>
                                        <IconUser
                                            source={
                                                PROFILE_ICONS[gstate.user.icon]
                                            }
                                        />
                                        <TableText>
                                            {gstate.user.username}
                                        </TableText>
                                        <TableText opacity={'0.5'} size={16}>
                                            #{gstate.user.tagid}
                                        </TableText>
                                    </TableContent>
                                </TableCol>
                                <TableCol width="15%">
                                    <TableContent align="center">
                                        <TableText>
                                            {gstate.user.wins} /{' '}
                                            {gstate.user.draws} /{' '}
                                            {gstate.user.loses}
                                        </TableText>
                                    </TableContent>
                                </TableCol>
                                <TableCol width="15%">
                                    <TableContent align="flex-end">
                                        <Icon
                                            size={20}
                                            source={
                                                UX_ASSETS[`icon-trophy2.png`]
                                            }
                                        />
                                        <TableText>
                                            {' '}
                                            {gstate.user.trophies}
                                        </TableText>
                                    </TableContent>
                                </TableCol>
                            </TableRow> */}
                        </TableScrollBorder>

                        <TableScrollBorder>
                            <TableRow disabled={true}>
                                <TableCol width="70%"></TableCol>
                                <TableCol width="15%">
                                    <TableContent align="flex-end">
                                        <TableContentTouchable
                                            disabled={disable_prev}
                                            onPress={onPrev}
                                        >
                                            <TableText
                                                opacity={
                                                    disable_prev ? '0.2' : '1'
                                                }
                                            >
                                                ◄ {translate('Prev')}
                                            </TableText>
                                        </TableContentTouchable>
                                    </TableContent>
                                </TableCol>
                                <TableCol width="15%">
                                    <TableContent align="flex-end">
                                        <TableContentTouchable
                                            disabled={disable_next}
                                            onPress={onNext}
                                        >
                                            <TableText
                                                opacity={
                                                    disable_next ? '0.2' : '1'
                                                }
                                            >
                                                {translate('Next')} ►
                                            </TableText>
                                        </TableContentTouchable>
                                    </TableContent>
                                </TableCol>
                            </TableRow>
                        </TableScrollBorder>
                    </TableList>
                </Table>
            </List>
            <Info>
                <Table background={COLOR.BROWNBLACK2 + '88'}>
                    <InfoTop>
                        <TableHeader>
                            <TableHeaderText>
                                {translate('Rewards')}
                            </TableHeaderText>
                        </TableHeader>
                        <InfoRewards>
                            <TableScrollBorder>
                                {state.rewards.map((reward, index) => {
                                    const rank =
                                        reward.rank.length === 1
                                            ? `#${reward.rank[0]}`
                                            : `#${reward.rank[0]} - #${reward.rank[1]}`

                                    return (
                                        <TableRow disabled={true} key={index}>
                                            <TableCol width="20%">
                                                <TableText>{rank}</TableText>
                                            </TableCol>
                                            <TableCol width="80%">
                                                <TableContent align="flex-end">
                                                    {reward.rewards.map(
                                                        (reward, index) => (
                                                            <InfoRewardItem
                                                                key={index}
                                                            >
                                                                <Icon
                                                                    size={30}
                                                                    source={
                                                                        UX_ASSETS[
                                                                            `icon-${formatRewardIcon(
                                                                                reward
                                                                            )}.png`
                                                                        ]
                                                                    }
                                                                />
                                                                <InfoRewardText>
                                                                    {formatReward(
                                                                        reward
                                                                    )}
                                                                </InfoRewardText>
                                                            </InfoRewardItem>
                                                        )
                                                    )}
                                                </TableContent>
                                            </TableCol>
                                        </TableRow>
                                    )
                                })}
                            </TableScrollBorder>
                        </InfoRewards>
                    </InfoTop>
                    <InfoBottom>
                        {state.rewards_date !== undefined && (
                            <>
                                <Footer>
                                    <InfoText>
                                        {translate(
                                            'This leaderboard resets on:'
                                        )}
                                    </InfoText>
                                </Footer>
                                <TabsInfo>
                                    <TabsInfoText>
                                        {new Date(
                                            Date.UTC(
                                                state.rewards_date.year,
                                                state.rewards_date.month,
                                                state.rewards_date.day
                                            )
                                        ).toGMTString()}
                                    </TabsInfoText>
                                </TabsInfo>
                            </>
                        )}
                    </InfoBottom>
                </Table>
            </Info>
        </Sides>
    )
}

function PlayerRow({
    player,
    score = 'trophies',
    background = COLOR.BROWNBLACK2,
}) {
    if (player === null) {
        return (
            <TableRow disabled={true} background={background}>
                <TableCol width="100%">
                    <TableContent />
                </TableCol>
            </TableRow>
        )
    }

    const wdl =
        score === 'trophies'
            ? `${player.wins} / ${player.draws} / ${player.loses}`
            : `${player.wins_week} / ${player.draws_week} / ${player.loses_week}`

    return (
        <TableRow
            onPress={() => {
                AUDIO.OPEN()
                changeView(VIEW.PROFILE, {
                    profile_id: player._id,
                    viewback: VIEW.LEADERBOARD,
                })
            }}
            background={background}
        >
            <TableCol width="10%">
                <TableContent>
                    {player.rank < 4 ? (
                        <Icon
                            source={UX_ASSETS[`icon-medal${player.rank}.png`]}
                        />
                    ) : (
                        <TableText color={COLOR.BEIGE4}>
                            {player.rank === undefined
                                ? '-'
                                : `#${player.rank}`}
                        </TableText>
                    )}
                </TableContent>
            </TableCol>
            <TableCol width="25%">
                <TableContent>
                    <IconUser source={PROFILE_ICONS[player.icon]} />
                    <TableText size={22}>{player.username}</TableText>
                    <TableText opacity={'0.5'} size={16}>
                        #{player.tagid}
                    </TableText>
                </TableContent>
            </TableCol>
            <TableCol width="35%">
                <TableContent align="center">
                    <InfoCardsPlayer visible={player.cards[5] > 0}>
                        <Icon size={25} source={UX_ASSETS[`icon-card5.png`]} />
                        <InfoCardsPlayerText>
                            x{player.cards[5]}
                        </InfoCardsPlayerText>
                    </InfoCardsPlayer>
                    <InfoCardsPlayer visible={player.cards[4] > 0}>
                        <Icon size={25} source={UX_ASSETS[`icon-card4.png`]} />
                        <InfoCardsPlayerText>
                            x{player.cards[4]}
                        </InfoCardsPlayerText>
                    </InfoCardsPlayer>
                    <InfoCardsPlayer visible={player.cards[3] > 0}>
                        <Icon size={25} source={UX_ASSETS[`icon-card3.png`]} />
                        <InfoCardsPlayerText>
                            x{player.cards[3]}
                        </InfoCardsPlayerText>
                    </InfoCardsPlayer>
                    <InfoCardsPlayer visible={player.cards[2] > 0}>
                        <Icon size={25} source={UX_ASSETS[`icon-card2.png`]} />
                        <InfoCardsPlayerText>
                            x{player.cards[2]}
                        </InfoCardsPlayerText>
                    </InfoCardsPlayer>
                    <InfoCardsPlayer visible={player.cards[1] > 0}>
                        <Icon size={25} source={UX_ASSETS[`icon-card1.png`]} />
                        <InfoCardsPlayerText>
                            x{player.cards[1]}
                        </InfoCardsPlayerText>
                    </InfoCardsPlayer>
                </TableContent>
            </TableCol>
            <TableCol width="15%">
                <TableContent align="center">
                    <TableText>{wdl}</TableText>
                </TableContent>
            </TableCol>
            <TableCol width="15%">
                <TableContent align="flex-end">
                    <>
                        {score === 'trophies' && (
                            <Icon
                                size={20}
                                source={UX_ASSETS[`icon-trophy2.png`]}
                            />
                        )}
                        <TableText> {player[score]}</TableText>
                    </>
                </TableContent>
            </TableCol>
        </TableRow>
    )
}

function formatRewardIcon({ asset, rarity }) {
    if (asset === REWARD_ASSET.CARD) {
        return `card${rarity}`
    }
    if (asset === REWARD_ASSET.USDT) {
        return `usdt`
    }
    if (asset === REWARD_ASSET.COINS) {
        return `coin`
    }
    if (asset === REWARD_ASSET.TULIPS) {
        return `tulip`
    }
}

function formatReward({ asset, amount }) {
    if (asset === REWARD_ASSET.USDT) {
        return `$${amount}`
    }
    if (asset === REWARD_ASSET.CARD) {
        return `x${amount}`
    }

    return amount
}

const ScrollView = styled.ScrollView``

const Content = styled.View`
    padding: ${px(PADDING.SIDES / 2)} ${px(PADDING.SIDES)};
`

const Sides = styled.View`
    flex-direction: row;
    justify-content: space-between;
    padding-top: ${px(PADDING.SIDES / 2)};
`

const List = styled.View`
    width: 60%;
`
const Info = styled.View`
    width: 38%;
`

const Icon = styled.Image`
    width: ${(p) => px(p.size || 30)};
    height: ${(p) => px(p.size || 30)};
`

const InfoTop = styled.View``
const InfoBottom = styled.View`
    flex: 1;
    justify-content: center;
    padding: ${px(50)};

    border-width: 1px 0 0 0;
    border-color: ${COLOR.BEIGE};
    border-style: solid;
`

const InfoCardsPlayer = styled.View`
    align-items: center;
    margin-left: ${px(2)};
    margin-right: ${px(2)};
    opacity: ${(p) => (p.visible ? '1' : '0.1')};
`
const InfoCardsPlayerText = styled.Text`
    font-family: Poppins-Medium;
    font-size: ${px(15)};
    line-height: ${px(15)};
    margin-top: ${px(3)};
    color: ${COLOR.BEIGE};
    opacity: 0.5;
`

const InfoRewards = styled.View``
const InfoRewardItem = styled.View`
    flex-direction: row;
    justify-content: flex-end;
    margin-left: ${px(30)};
    // min-width: ${px(90)};
    // background: red;
`
const InfoRewardText = styled.Text`
    font-family: ${(p) => (p.bold ? 'Poppins-Bold' : 'Poppins-Medium')};
    opacity: ${(p) => (p.bold ? '0.5' : '1')};
    font-size: ${(p) => px(p.size || 20)};
    color: ${(p) => p.color || COLOR.BEIGE};
    margin-left: ${px(5)};
`

const IconUser = styled.Image`
    width: ${px(32)};
    height: ${px(32)};
    border-radius: 50%;
    border: ${px(3)} solid ${COLOR.BEIGE};
    margin-right: ${px(15)};
`

const Footer = styled.View`
    padding-top: ${px(50)};
    justify-content: center;
    align-items: center;
`

const InfoText = styled.Text`
    font-family: Poppins-Medium;
    font-size: ${(p) => px(p.size || 18)};
    color: ${COLOR.BEIGE};
    opacity: 0.5;
    text-align: center;
    width: ${(p) => p.width || 'auto'};
`
