export const ERROR = {
    //SERVER
    SERVER_MAINTENANCE: 'SERVER_MAINTENANCE',
    GAME_NOT_FOUND: 'GAME_NOT_FOUND',
    INVALID_EMAIL: 'INVALID_EMAIL',
    INVALID_CODE: 'INVALID_CODE',
    INVALID_CODE_WAITING_TIME: 'INVALID_CODE_WAITING_TIME',
    INVALID_USERNAME: 'INVALID_USERNAME',
    INVALID_USERNAMEFULL: 'INVALID_USERNAMEFULL',
    NOT_POSSIBLE_TO_SEND_EMAIL: 'NOT_POSSIBLE_TO_SEND_EMAIL',
    CANCELATION_PENALTY: 'CANCELATION_PENALTY',
    RESIGN_PENALTY: 'RESIGN_PENALTY',

    // CLIENT
    INVALID_APP_VERSION: 'INVALID_APP_VERSION',
    NOT_CONNECTION: 'NOT_CONNECTION',
    ENDPOINT_NOT_FOUND: 'ENDPOINT_NOT_FOUND',
}

export const ERROR_DESCRIPTION = {
    [ERROR.SERVER_MAINTENANCE]: 'Server under maintenance',
    [ERROR.GAME_NOT_FOUND]: 'Game not found',
    [ERROR.INVALID_EMAIL]: 'Please enter a valid email',
    [ERROR.INVALID_CODE]: 'The code is invalid or has expired',
    [ERROR.INVALID_CODE_WAITING_TIME]: 'Wait a few seconds and try again',
    [ERROR.INVALID_USERNAME]: 'Only letters and numbers are allowed',
    [ERROR.INVALID_USERNAMEFULL]: 'Invalid username and tagid',
    [ERROR.NOT_POSSIBLE_TO_SEND_EMAIL]:
        'It has not been possible to send the email, try again in a few seconds',
    [ERROR.INVALID_APP_VERSION]: 'Invalid app version',
    [ERROR.NOT_CONNECTION]: 'No connection to the server',
    [ERROR.ENDPOINT_NOT_FOUND]: 'Endpoint not found',
}
