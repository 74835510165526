import React, { useEffect } from 'react'
import styled from '@emotion/native'
import { GAME_EVENT } from 'conkis-core'
import createEventEmitter from 'conkis-core/src/utils/createEventEmitter'
import { AUDIO_STOP_ALL } from '~/audio'
import { COLOR, CONNECTION, VIEW, LOCALSTORAGE } from '~/const'
import { UX_ASSETS } from '~/const/assets'
import { px, localStorageRemove } from '~/utils/device'
import { changeView } from '~/store'
import { useGlobalState, useLocalState } from '~/store/hooks'
import { translate, getError } from '~/locale'
import { Server, getConnection } from '~/server'
import Button from '~/components/stateless/Button'
import Logic from '~/components/views/Game/Logic'

export default React.memo(function () {
    const [{ game_id, user }, setGlobalState] = useGlobalState()
    const [state, setState] = useLocalState({
        game: null,
        emitter: null,
        crashed: false,
    })

    useEffect(() => {
        onSubscribe()

        // return () => {
        //     if (state.game !== null) {
        //         state.emitter.removeAll()
        //         try {
        //             Server.gameUnsubscribe()
        //         } catch (e) {}
        //     }
        // }
    }, [])

    useEffect(() => {
        return () => {
            AUDIO_STOP_ALL()

            if (state.game !== null) {
                state.emitter.removeAll()
            }
        }
    }, [])

    async function onSubscribe() {
        try {
            const emitter = createEventEmitter()
            const game = await Server.gameSubscribe({
                game_id,
                subscription: emitter.emit,
            })
            emitter.on(GAME_EVENT.CANVAS_UNREADY, () => {
                setState({ crashed: true })
            })
            setState({ game, emitter })
        } catch (e) {
            localStorageRemove(LOCALSTORAGE.GAME_ID, game_id)
            changeView(VIEW.HOME, { game_id: null })
            // console.log(e)
            // setState({ error: getError(e) })
        }
    }

    function onReload() {
        state.emitter.removeAll()
        setState({ game: null, emitter: null, crashed: false })
        onSubscribe()
    }

    if (state.crashed) {
        return (
            <Container>
                <ButtonContainer>
                    <Button
                        width={500}
                        label={translate('Reload game')}
                        onClick={onReload}
                    />
                </ButtonContainer>
            </Container>
        )
    }

    return state.game === null ? (
        <Container>
            <TextContainer>
                <Text>{translate('Loading')}...</Text>
            </TextContainer>
        </Container>
    ) : (
        <Logic
            emitter={state.emitter}
            game={state.game}
            user={user}
            onReload={onReload}
        />
    )
})

function Container({ children }) {
    return (
        <ContainerView
            source={UX_ASSETS['bg-pattern.png']}
            imageStyle={{ resizeMode: 'repeat' }}
        >
            <Content>{children}</Content>
        </ContainerView>
    )
}

const ContainerView = styled.ImageBackground`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: ${COLOR.BEIGE2};
`
const Content = styled.View`
    width: ${px(550)};
`
const TextContainer = styled.View`
    border-radius: ${px(10)};
    width: 100%;
    height: ${px(100)};
    background: ${COLOR.BROWN + '28'};
    justify-content: center;
    align-items: center;
`
const Text = styled.Text`
    font-size: ${px(40)};
    color: ${COLOR.BROWN};
    font-family: ChangaOne;
    text-align: center;
`
const ButtonContainer = styled.View`
    margin-top: ${px(50)};
`
