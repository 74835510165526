import React from 'react'
import { TouchableOpacity } from 'react-native'
import styled from '@emotion/native'
import { changeView } from '~/store'
import { AUDIO } from '~/audio'
import { COLOR, VIEW } from '~/const'
import { UX_ASSETS } from '~/const/assets'
import { px } from '~/utils/device'
import { translate } from '~/locale'

export default function Footer({}) {
    return (
        <Background
            source={UX_ASSETS['bg-weapons.png']}
            imageStyle={{ resizeMode: 'repeat' }}
        >
            <Centered
                source={UX_ASSETS['footer-bg.png']}
                imageStyle={{
                    resizeMode: 'stretch', // or 'cover' 'repeat',
                }}
            >
                <Content>
                    <ButtonSeparator />
                    <TouchableOpacity
                        onPress={() => {
                            AUDIO.OPEN()
                            changeView(VIEW.CARDS)
                        }}
                    >
                        <ButtonContainer>
                            <Button>
                                <ButtonImage
                                    source={UX_ASSETS['icon-menu-cards.png']}
                                />
                                <ButtonLabel>
                                    {translate('My Cards')}
                                </ButtonLabel>
                            </Button>
                        </ButtonContainer>
                    </TouchableOpacity>

                    <ButtonSeparator />

                    <TouchableOpacity
                        onPress={() => {
                            AUDIO.OPEN()
                            changeView(VIEW.LEADERBOARD)
                        }}
                    >
                        <ButtonContainer>
                            <Button>
                                <ButtonImage
                                    scale={1}
                                    source={
                                        UX_ASSETS['icon-menu-leaderboard.png']
                                    }
                                />
                                <ButtonLabel>
                                    {translate('Leaderboard')}
                                </ButtonLabel>
                            </Button>
                        </ButtonContainer>
                    </TouchableOpacity>

                    <ButtonSeparator />

                    <TouchableOpacity
                        onPress={() => {
                            AUDIO.OPEN()
                            changeView(VIEW.PLAY)
                        }}
                    >
                        <ButtonContainer>
                            <Button>
                                <ButtonImage
                                    scale={1.2}
                                    source={UX_ASSETS['icon-menu-play.png']}
                                />
                                <ButtonLabel size={40} shadow={true}>
                                    {translate('Play')}
                                </ButtonLabel>
                            </Button>
                        </ButtonContainer>
                    </TouchableOpacity>

                    <ButtonSeparator />

                    <TouchableOpacity
                        onPress={() => {
                            AUDIO.OPEN()
                            changeView(VIEW.LIVEGAMES)
                        }}
                    >
                        <ButtonContainer>
                            <Button>
                                <ButtonImage
                                    scale={1}
                                    source={
                                        UX_ASSETS['icon-menu-livegames.png']
                                    }
                                />
                                <ButtonLabel>
                                    {translate('Live Games')}
                                </ButtonLabel>
                            </Button>
                        </ButtonContainer>
                    </TouchableOpacity>

                    <ButtonSeparator />

                    <TouchableOpacity
                        onPress={() => {
                            AUDIO.OPEN()
                            changeView(VIEW.SHOP)
                        }}
                    >
                        <ButtonContainer>
                            <Button>
                                <ButtonImage
                                    source={UX_ASSETS['icon-menu-shop.png']}
                                />
                                <ButtonLabel>{translate('Shop')}</ButtonLabel>
                            </Button>
                        </ButtonContainer>
                    </TouchableOpacity>

                    <ButtonSeparator />
                </Content>
            </Centered>
        </Background>
    )
}

function ButtonSeparator() {
    return (
        <ButtonSeparatorContainer source={UX_ASSETS['footer-separator.png']} />
    )
}

const Background = styled.ImageBackground`
    background: #271d15;
    width: 100%;
    height: ${px(150)};
`

const Centered = styled.ImageBackground`
    width: 100%;
    align-items: center;
    height: ${px(127)};
`

const Content = styled.ImageBackground`
    flex-direction: row;
    height: 100%;
`

const ButtonSeparatorContainer = styled.Image`
    width: ${px(15)};
    height: 100%;
`

const ButtonContainer = styled.ImageBackground`
    min-width: ${px(225)};
    height: ${px(120)};
    align-items: center;
    justify-content: flex-end;
`

const Button = styled.View`
    top: ${px(-30)};
    align-items: center;
    width: 100%;
    // background: blue;
`

const ButtonLabel = styled.Text`
    color: ${(p) => p.color || COLOR.BROWN};
    font-size: ${(p) => px(p.size || 33)};
    font-family: ChangaOne;
    line-height: ${px(50)};
`
// ${(p) => p.shadow && `text-shadow: 0 ${px(4)} 0 ${COLOR.BROWN + '44'};`}

const ButtonImage = styled.Image`
    width: ${(p) => px((p.scale || 1) * 100)};
    height: ${(p) => px((p.scale || 1) * 87)};
`
