import React, { useState, useEffect } from 'react'
import styled from '@emotion/native'
import { TEAM } from 'conkis-core'
import { COLOR, VIEW } from '~/const'
import { UX_ASSETS } from '~/const/assets'
import { setSounds, setMusic, changeView } from '~/store'
import { useGlobalState } from '~/store/hooks'
import { translate } from '~/locale'
import {
    px,
    isDesktop,
    isElectron,
    isFullScreen,
    toggleFullscreen,
} from '~/utils/device'
import { AUDIO } from '~/audio'
import Dialog from '~/components/stateless/Dialog'
import Button from '~/components/stateless/Button'
import Checkbox from '~/components/stateless/Checkbox'
import { FormField, FormFieldSeparator } from '~/components/stateless/Form'

const TAB = {
    GAMEPLAY: 'GAMEPLAY',
    SOUNDS: 'SOUNDS',
    UX: 'UX',
}

export default function SettingsGame({
    state,
    board,
    onDrawOffer,
    onResign,
    onReload,
    onClose,
    onBackground,
}) {
    const [{ sounds, music }] = useGlobalState('sounds', 'music')
    const [fullscreen, setFullScreen] = useState(isFullScreen())
    const [tab, setTab] = useState(TAB.GAMEPLAY)
    // console.log(state)
    const team_id = state.team_id
    // const team_id_enemy = team_id === TEAM.TEAM_1 ? TEAM.TEAM_2 : TEAM.TEAM_1
    const drawenabled = board.state.teams[team_id].drawing === null

    useEffect(() => {
        document.addEventListener(
            'fullscreenchange',
            updateStateFullscreenState,
            false
        )
        return () => {
            document.removeEventListener(
                'fullscreenchange',
                updateStateFullscreenState,
                false
            )
        }
    }, [])

    function onSetTab(tab) {
        AUDIO.CLICK()
        setTab(tab)
    }

    function onMusic() {
        AUDIO.CLICK()
        const value = !music
        setMusic(value)
        AUDIO.MUSIC(value)
    }

    function onSounds() {
        AUDIO.CLICK()
        const value = !sounds
        if (!value) {
            AUDIO_STOP_ALL()
        }
        setSounds(value)
    }

    function updateStateFullscreenState() {
        setFullScreen(isFullScreen())
    }

    return (
        <Dialog
            width={850}
            height={600}
            onClose={onClose}
            onBackground={onBackground}
            background={UX_ASSETS['bg-pattern.png']}
        >
            <Container>
                <TabsContainer>
                    <Tabs>
                        <Tab
                            selected={tab === TAB.GAMEPLAY}
                            onPress={() => onSetTab(TAB.GAMEPLAY)}
                        >
                            <TabLabel>{translate('Game')}</TabLabel>
                        </Tab>
                        <Tab
                            selected={tab === TAB.AUDIO}
                            onPress={() => onSetTab(TAB.AUDIO)}
                        >
                            <TabLabel>{translate('Audio')}</TabLabel>
                        </Tab>
                        <Tab
                            selected={tab === TAB.UX}
                            onPress={() => onSetTab(TAB.UX)}
                        >
                            <TabLabel>{translate('UX')}</TabLabel>
                        </Tab>
                    </Tabs>
                </TabsContainer>

                <Content>
                    {tab === TAB.GAMEPLAY && (
                        <>
                            {!state.spectator && (
                                <>
                                    <FormField>
                                        <Button
                                            width={'100%'}
                                            fontSize={35}
                                            label={translate('Offer Draw')}
                                            disabled={!drawenabled}
                                            onClick={() => {
                                                AUDIO.CLOSE()
                                                onDrawOffer()
                                            }}
                                        />
                                    </FormField>
                                    <FormFieldSeparator />
                                    <FormField>
                                        <Button
                                            width={'100%'}
                                            fontSize={35}
                                            color={COLOR.RED}
                                            label={translate('Resign')}
                                            onClick={() => {
                                                AUDIO.CLOSE()
                                                onResign()
                                            }}
                                        />
                                    </FormField>
                                </>
                            )}

                            {state.spectator && (
                                <FormField>
                                    <Button
                                        width={'100%'}
                                        fontSize={35}
                                        color={COLOR.BLUEDARK}
                                        label={translate('Exit')}
                                        onClick={() => {
                                            AUDIO.CLOSE()
                                            changeView(VIEW.LIVEGAMES, {
                                                game_id: null,
                                            })
                                        }}
                                    />
                                </FormField>
                            )}
                        </>
                    )}

                    {tab === TAB.AUDIO && (
                        <>
                            <FormField height={110}>
                                <Checkbox
                                    label={translate('Music')}
                                    checked={music}
                                    onClick={onMusic}
                                />
                            </FormField>
                            <FormFieldSeparator />
                            <FormField height={110}>
                                <Checkbox
                                    label={translate('Sounds')}
                                    checked={sounds}
                                    onClick={onSounds}
                                />
                            </FormField>
                        </>
                    )}

                    {tab === TAB.UX && (
                        <>
                            {isDesktop() && !isElectron() && (
                                <>
                                    <FormField height={110}>
                                        <Checkbox
                                            label={translate('Full Screen')}
                                            checked={fullscreen}
                                            onClick={toggleFullscreen}
                                        />
                                    </FormField>
                                    <FormFieldSeparator />
                                </>
                            )}

                            <FormField>
                                <Button
                                    width={'100%'}
                                    fontSize={35}
                                    label={translate('Reload Client')}
                                    onClick={() => {
                                        AUDIO.CLOSE()
                                        onReload()
                                    }}
                                />
                            </FormField>
                        </>
                    )}
                </Content>
            </Container>
        </Dialog>
    )
}

const Container = styled.View`
    height: 100%;
    width: 100%;
    display: flex;
    padding: ${px(30)};
`

const TabsContainer = styled.View`
    display: flex;
    justify-content: center;
    height: ${px(80)};
`
const Tabs = styled.View`
    flex-direction: row;
`
const Tab = styled.TouchableOpacity`
    background: ${(p) => (p.selected ? COLOR.BEIGE2 : 'transparent')};
    border: 3px solid ${COLOR.BEIGE2};
    padding: ${px(10)} ${px(40)};
    border-radius: ${px(30)};
    margin-right: ${px(15)};
`

const TabLabel = styled.Text`
    font-family: Poppins-Bold;
    color: ${COLOR.BROWN};
    font-size: ${px(22)};
`

const Content = styled.View`
    flex: 1;
    justify-content: center;
    align-items: center;
`
