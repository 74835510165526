export function supplant(text, params) {
    return text.replace(/\${([^{}]*)}/g, (a, b) => {
        const r = params[b]
        return typeof r === 'string' || typeof r === 'number' ? r : a
    })
}

export function colorLuminance(hex, lum) {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '')
    if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
    }
    lum = lum || 0

    // convert to decimal and change luminosity
    var rgb = '#',
        c,
        i
    for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16)
        c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16)
        rgb += ('00' + c).substr(c.length)
    }

    return rgb
}

export function colorOpacity(hex, opacity = 1) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    const rgb = {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
    }

    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`
}

export function cardToAsset(card) {
    return card.rarity + card.type.toLowerCase()
}

export function createRemoteNow(remote_now) {
    const diff = Date.now() - remote_now
    return () => Date.now() - diff
}

export function secondsToTimeRaw(seconds) {
    const date = new Date(0)
    date.setSeconds(seconds) // specify value for SECONDS here
    const t = date.toISOString().substring(11, 19).split(':')
    return { h: t[0], m: t[1], s: t[2] }
}

export function secondsToTime(seconds) {
    const { h, m, s } = secondsToTimeRaw(seconds)
    const string = m + ':' + s
    return h !== '00' ? h + ':' + string : string
}

export function msToSeconds(ms) {
    return Math.round(ms / 1000)
}

export function getReadableTimestamp(date) {
    return new Date(date).toString().split(' GMT')[0]
}

export function timeAgo(timestamp) {
    const now = Date.now()
    const diffInSeconds = Math.floor((now - timestamp) / 1000)

    if (diffInSeconds < 60) {
        return `${diffInSeconds} secs`
    }

    const diffInMinutes = Math.floor(diffInSeconds / 60)
    if (diffInMinutes < 60) {
        return `${diffInMinutes} min${diffInMinutes > 1 ? 's' : ''}`
    }

    const diffInHours = Math.floor(diffInMinutes / 60)
    if (diffInHours < 24) {
        return `${diffInHours} hour${diffInHours > 1 ? 's' : ''}`
    }

    const diffInDays = Math.floor(diffInHours / 24)
    return `${diffInDays} day${diffInDays > 1 ? 's' : ''}`
}
