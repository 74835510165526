import styled from '@emotion/native'
import { px } from '~/utils/device'
import { COLOR } from '~/const'

export const Table = styled.View`
    width: ${(p) => p.width || 'auto'};
    height: 100%;
    border-radius: ${px(20)};
    background: ${(p) => p.background || COLOR.BROWNBLACK};
    border: ${px(8)} solid ${COLOR.BEIGE};
`
export const TableHeader = styled.View`
    width: 100%;
    align-items: center;
    flex-direction: row;
    padding: ${px(15)} ${px(20)};
    border-width: 0 0 1px 0;
    border-color: ${COLOR.BEIGE};
    border-style: solid;
`
export const TableHeaderText = styled.Text`
    font-family: Poppins-Bold;
    font-size: ${px(25)};
    color: ${COLOR.BEIGE};
`
export const TableList = styled.View``
export const TableScroll = styled.ScrollView``
export const TableScrollBorder = styled.View`
    // padding-right: 5px;
    border-width: 1px 0 0 0;
    border-color: ${COLOR.BEIGE};
    border-style: solid;
`

export const TableRow = styled.TouchableOpacity`
    flex-direction: row;
    width: 100%;
    height: ${(p) => px(p.height || 60)};
    padding-left: ${(p) => px(p.paddingLeft || 20)};
    padding-right: ${(p) => px(p.paddingRight || 20)};
    background: ${(p) => p.background || 'transparent'};
    align-items: center;
    border-width: 0 0 1px 0;
    border-color: ${COLOR.BEIGE + '33'};
    border-style: solid;
`
export const TableCol = styled.View`
    width: ${(p) => p.width};
    flex-direction: row;
    justify-content: ${(p) => p.align || 'flex-start'};
`
export const TableContent = styled.View`
    flex-direction: row;
    justify-content: ${(p) => p.align || 'flex-start'};
    align-items: center;
    width: 100%;
    min-height: ${px(35)};
`
export const TableContentTouchable = styled.TouchableOpacity``

export const TableText = styled.Text`
    padding: ${(p) => p.padding || '0'};
    font-family: ${(p) => (p.bold ? 'Poppins-Bold' : 'Poppins-Medium')};
    opacity: ${(p) => p.opacity || '1'};
    font-size: ${(p) => px(p.size || 20)};
    color: ${(p) => p.color || COLOR.BEIGE};
`
