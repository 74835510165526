import React, { useEffect } from 'react'
import styled from '@emotion/native'
import { COLOR, LOCALSTORAGE } from '~/const'
import { useLocalState } from '~/store/hooks'
import { translate } from '~/locale'
import { px, localStorageSet } from '~/utils/device'
import { AUDIO } from '~/audio'
import Dialog from '~/components/stateless/Dialog'
import Button from '~/components/stateless/Button'
import DropDown from '~/components/stateless/DropDown'
import { FormLabel } from '~/components/stateless/Form'

const TROPHIES_OPTIONS = [10000, 8000, 6000, 4000, 2000]

export default function SettingsPlay({
    trophies_min,
    trophies_max,
    onConfirm,
    onClose,
}) {
    const [state, setState] = useLocalState({
        min_open: false,
        max_open: false,
        min_selected: TROPHIES_OPTIONS.indexOf(trophies_min),
        max_selected: TROPHIES_OPTIONS.indexOf(trophies_max),
    })
    const open = state.min_open || state.max_open

    return (
        <Dialog
            width={850}
            height={600}
            onClose={onClose}
            onBackground={onClose}
        >
            <Container>
                <Content>
                    <Block zIndex={open ? '1' : '0'}>
                        <FormLabel>{translate('Trophies Range')}</FormLabel>
                        <BlockRow>
                            <DropDown
                                open={state.min_open}
                                selected={state.min_selected}
                                options={TROPHIES_OPTIONS.map(
                                    (value) => '-' + value.toString()
                                )}
                                width={'40%'}
                                fontSize={35}
                                onOpen={() => {
                                    AUDIO.CLICK()
                                    setState({ min_open: !state.min_open })
                                }}
                                onSelect={(index) => {
                                    setState({
                                        min_selected: index,
                                    })
                                }}
                            />
                            <RangeSeparator>{'< >'}</RangeSeparator>
                            <DropDown
                                open={state.max_open}
                                selected={state.max_selected}
                                options={TROPHIES_OPTIONS.map(
                                    (value) => '+' + value.toString()
                                )}
                                width={'40%'}
                                fontSize={35}
                                onOpen={() => {
                                    AUDIO.CLICK()
                                    setState({ max_open: !state.max_open })
                                }}
                                onSelect={(index) => {
                                    setState({
                                        max_selected: index,
                                    })
                                }}
                            />
                        </BlockRow>
                    </Block>
                    <Block zIndex={open ? '0' : '1'}>
                        <Button
                            width={'100%'}
                            fontSize={35}
                            color={COLOR.GREENDARK}
                            label={translate('Confirm')}
                            onClick={() => {
                                const trophies_min =
                                    TROPHIES_OPTIONS[state.min_selected]
                                const trophies_max =
                                    TROPHIES_OPTIONS[state.max_selected]

                                localStorageSet(LOCALSTORAGE.TROPHIES_RANGE, [
                                    trophies_min,
                                    trophies_max,
                                ])

                                onConfirm({ trophies_min, trophies_max })
                            }}
                        />
                    </Block>
                    <Block zIndex={'0'}>
                        <InfoText>
                            {translate(
                                'Use this Trophies filter to match with players of your skill level.'
                            )}
                        </InfoText>
                    </Block>
                </Content>
            </Container>
        </Dialog>
    )
}

const Container = styled.View`
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`
const Content = styled.View`
    width: 80%;
`
const Block = styled.View`
    padding: ${px(25)} 0;
    z-index: ${(p) => p.zIndex || '0'};
`
const BlockRow = styled.View`
    flex-direction: row;
    justify-content: space-between;
    z-index: 2;
    position: relative;
`
const RangeSeparator = styled.Text`
    font-size: ${px(40)};
    color: ${COLOR.BROWN};
    font-family: ChangaOne;
    display: flex;
    align-items: center;
`

const InfoText = styled.Text`
    font-size: ${px(16)};
    color: ${COLOR.BROWN};
    font-family: Poppins-Medium;
    align-items: center;
    opacity: 0.5;
    width: 100%;
    display: flex;
    justify-content: center;
`
