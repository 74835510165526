import styled from '@emotion/native'
import { px } from '~/utils/device'
import { COLOR } from '~/const'

export const FormField = styled.View`
    width: 100%;
    height: ${(p) => px(p.height || 100)};
`

export const FormFieldSeparator = styled.View`
    height: ${px(30)};
`

export const FormLabel = styled.Text`
    font-size: ${px(24)};
    font-family: Poppins-Black;
    color: ${COLOR.BROWN};
    display: flex;
    align-items: center;
    height: ${px(70)};
    padding-left: ${px(10)};
`
