import React from 'react'
import styled from '@emotion/native'
import app_package from '../../../package.json'
import * as core from 'conkis-core'
import { COLOR } from '~/const'
import { UX_ASSETS } from '~/const/assets'
import { Server } from '~/server'
import { onLogout, setSounds } from '~/store'
import { useGlobalState } from '~/store/hooks'
import { translate } from '~/locale'
import { px, openUrl, isElectron, windowClose } from '~/utils/device'
import { AUDIO } from '~/audio'
import Dialog from '~/components/stateless/Dialog'
import Button from '~/components/stateless/Button'
import Checkbox from '~/components/stateless/Checkbox'

export default function Settings({ onClose, onBackground }) {
    const [{ sounds }] = useGlobalState('sounds')

    return (
        <Dialog
            width={850}
            height={isElectron() ? 700 : 600}
            onClose={onClose}
            onBackground={onBackground}
        >
            <Container>
                <Content>
                    <Block>
                        <Checkbox
                            label={translate('Sounds')}
                            checked={sounds}
                            onClick={() => {
                                const value = !sounds
                                if (!value) {
                                    AUDIO_STOP_ALL()
                                }
                                setSounds(value)
                                AUDIO.CLICK()
                            }}
                        />
                    </Block>
                    <Block>
                        <Button
                            icon="icon-discord.png"
                            bg={null}
                            color="rgb(111, 133, 213)"
                            colorShadow="rgb(94, 112, 178)"
                            width={'100%'}
                            iconSize={40}
                            iconWidth={200}
                            onClick={() => {
                                AUDIO.OPEN()
                                openUrl('https://discord.gg/uNccPs6Q8B')
                            }}
                        />
                    </Block>
                    {isElectron() && (
                        <Block>
                            <Button
                                width={'100%'}
                                fontSize={35}
                                label={translate('Exit')}
                                onClick={() => {
                                    AUDIO.CLOSE()
                                    windowClose()
                                }}
                            />
                        </Block>
                    )}
                    <Block>
                        <Button
                            width={'100%'}
                            fontSize={35}
                            label={translate('Log Out')}
                            color={COLOR.RED}
                            onClick={() => {
                                AUDIO.CLOSE()
                                onLogout(Server)
                            }}
                        />
                    </Block>
                </Content>
                <Versions>
                    <Version>app: {app_package.version}</Version>
                    <Version>core: {core.version}</Version>
                </Versions>
            </Container>
        </Dialog>
    )
}

const Container = styled.View`
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`
const Content = styled.View`
    width: 80%;
`
const Block = styled.View`
    padding: ${px(25)} 0;
`
const Versions = styled.View`
    margin-top: ${px(15)};
    flex-direction: row;
    justify-content: center;
    width: 80%;
`
const Version = styled.Text`
    color: ${COLOR.BROWN};
    font-size: ${px(20)};
    font-family: Poppins-Medium;
    margin-right: ${px(20)};
    opacity: 0.5;
`
